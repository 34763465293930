.displayed-door-group {
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
}

.displayed-door {
    margin: 1rem;
}

.modal-90w {
    width: 90%;
    max-width: none !important;
}

.povprasevanje-ceral-info {
    /* background-color: #ebebeb; */
    padding: 1rem;
    border: 1px solid #dee2e6;
}