.inox {
    fill:#E0E0E0;
    stroke:#000000;
    stroke-miterlimit:10;
}

.izrez {
    opacity:0.3;
    fill:#808080;
    stroke:#000000;
    stroke-miterlimit:10;
}

.izrez-inox {
    opacity: 1;
    fill: url("#inox");
}