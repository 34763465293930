.main-window {
    height: 100vh;
    overflow: hidden;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding: .5rem 1rem;
}

.footer a {
    color: #0c457d;
}

.footer a:hover {
    color: #0056b3;
}

.configuration-menu {
    width: 33.333333%;
}

.navigation-top {
    display: flex;
    align-items: center;
    border-bottom: 2px solid rgba(0,0,0,.1);
    height: 10vh;
}

.ceral-logo img {
    width: 100%;
}

.navigation-top-tabs {
    width: 100%;
}

.configurator {
    width: 66.666667%;
}

.configuratorSidebar {
    overflow: auto;
    background-color: #fff;
    height: 90vh;
    padding: .5rem 1rem;
}

.configurator-visual-panel {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* @media (max-height: 623px) {
    .configurator-visual-panel {
        height: 75%;
    }
} */

.buttons-wall-bg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem;
}

.buttons-ozadje-pogled {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@media only screen and (max-width: 848px) {
    .buttons-wall-bg {
        justify-content: center;
    }
}

.door-visual__grid {
    display: table;
    table-layout: auto;
    white-space: nowrap;
    height: 100%;
    width: 100%;
}

.door-visual__row {
    display: table-row;
}

.door-visual__panel {
    display: table-cell;
    position: relative;
}

/* @media (min-width: 1250px) {
    .ozadje-zunanje-1.A1 {
        background-size: 623px!important;
    }
    .ozadje-zunanje-1.A2 {
        background-size: 623px!important;
    }
    .ozadje-zunanje-1.A3 {
        background-size: 623px!important;
    }
    .ozadje-zunanje-1.B1 {
        background-size: 740px!important;
    }
    .ozadje-zunanje-1.B2 {
    }
    .ozadje-zunanje-1.B3 {
        background-size: 740px!important;
    }
    .ozadje-zunanje-1.C1 {
        background-size: 470px!important;
    }
    .ozadje-zunanje-1.C2 {
        background-size: 470px!important;
    }
    .ozadje-zunanje-1.C3 {
        background-size: 470px!important;
    }
}

@media (min-width: 1920px) {
    .ozadje-zunanje-1.A1 {
        background-size: 675px!important;
    }
    .ozadje-zunanje-1.A2 {
        background-size: 675px!important;
    }
    .ozadje-zunanje-1.A3 {
        background-size: 675px!important;
    }
    .ozadje-zunanje-1.B1 {
        background-size: 801px!important;
    }
    .ozadje-zunanje-1.B2 {
    }
    .ozadje-zunanje-1.B3 {
        background-size: 801px!important;
    }
    .ozadje-zunanje-1.C1 {
        background-size: 510px!important;
    }
    .ozadje-zunanje-1.C2 {
        background-size: 510px!important;
    }
    .ozadje-zunanje-1.C3 {
        background-size: 510px!important;
    }
} */



.door-container {
    /* position: absolute;
    left: 39%;
    bottom: 15%; */
    /* width: 15rem; */
}

.door-container svg {
    
}

.ozadje-1 {
    background-image: url('./assets/images/ozadje-1.jpg');
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
}

.drawing-area {
    background-image: url('./assets/images/wall.png');
    height: 100%;
    width: 100%;
    transition-duration: 300ms;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.tla-notri-1 {
    background-image: url("./assets/images/tla-notri-1.jpg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    width: 100%;
    height: 6rem;
    margin-top:-.5rem;
}

.tla-zunaj-1 {
    background-image: url("./assets/images/tla-zunaj-1.jpg");
    background-repeat: no-repeat;
    background-position: center top; 
    width: 100%;
    height: 6rem;
    margin-top:-.5rem;
}

.wall-white {
    background-color: #fefefe;
}

.wall-grey {
    background-color: #000000c7;
}

.opeka {
    background-image: url("./assets/images/brick-wall.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.button-door-models {
    margin: .25rem;
}

.button-door-models p {
    margin: 0;
    font-weight: 600;
}

.button-frame-models-group {
    display: flex;
    flex-wrap: wrap;
}

.button-frame-models {
    margin: .25rem;
    width: 10rem;
}

.button-frame-models p {
    margin: 0;
    font-weight: 600;
}

.button-frame-models img {
    width: 100%;
}

.buttonGroup {
    display: flex;
    justify-content: space-around;
}

.RAL-button {
    color: rgba(255, 255, 255, .75)!important;
    margin: .2rem;
}

.bold {
    font-weight: 600;
}

.RAL-button-inv {
    color: rgba(0, 0, 0, .75)!important;
    margin: .2rem;
}

.RAL-button:hover {
    color: rgba(255, 255, 255, 1)!important;
}

.RAL-button-inv:hover {
    color: rgba(0, 0, 0, 1)!important;
}

.fixed {
    position: fixed;
    top: 80px;
    right: 10%;
}

.fixed-navigation {
    position: fixed;
}

.fixed-navigation-space {
    top: 80px;
}

.relative {
    position: relative;
}

.button-povprasevanje {
    position: absolute;
    bottom: 30px;
    right: 1rem;
}

.button-color-option {
    height: 4rem;
    width: 10rem;
    padding: 1rem;
    text-align: center;
    border: 2px solid #343a40;
    border-radius: 5px;
    font-weight: 600;
    background-color: #fdfdfd;
    margin: 1rem;
}

.button-color-option:hover {
    cursor: pointer;
    border: 2px solid #ff8800;
    transition: 300ms;
}

.button-color {
    height: 4rem;
    width: 10rem;
    /* padding: 1rem; */
    text-align: center;
    border-radius: 5px;
    font-weight: 600;
    margin: .5rem;
}

.button-color:hover {
    cursor: pointer;
    border: 2px solid #ff8800;
    transition: 100ms;
}

.button-color-active {
    cursor: pointer;
    border: 2px solid #ff8800;
}

.colors-preview {
    height: 4rem;
    width: 10rem;
    padding: 1rem;
    border: 1px solid #343a40;
    border-radius: 5px;
    margin: 1rem auto;
}

.decor-colors-group {
    display: flex;
    flex-wrap: wrap;
}

.decor-colors-button {
    height: 4rem;
    padding: 1rem;
    margin: .5rem;
    text-align: center;
    width: 40%;
    border-radius: 5px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    border: 2px solid #343a40;
}

.decor-colors-button:hover {
    border: 2px solid #ff8800;
    transition: 300ms;
}

.button-flex {
    display: flex;
    align-items: center;
}

.mobile-view-options {
    position: absolute;
    top: 80vh;
    left: 1rem;
    height: 3em;
    width: 3em;
    background-color: #fff;
    border-radius: 5px;
    padding: .25rem;
}

.mobile-view-options:hover {
    cursor: pointer;
    animation-name: rotate; 
    animation-duration: 5s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

.icon-size-1 {
    height: 1em;
    width: 1em;
}

.icon-size-2 {
    height: 2em;
    width: 2em;
}

.responsiveConfigMenuOpened {
    width: 100%;
    position: absolute;
    bottom: 0;
    top: 9rem;
}

@media only screen and (max-width: 585px) {
    .responsiveConfigMenuOpened {
        top: 9.5rem;
    }
}

@media only screen and (max-width: 450px) {
    .responsiveConfigMenuOpened {
        top: 12rem;
    }
}
@media only screen and (max-width: 438px) {
    .responsiveConfigMenuOpened {
        top: 12.5rem;
    }
}

/* @media only screen and (max-height: 623px) {
    .responsiveConfigMenuOpened {
        top: 12rem;
    }
} */

.responsiveViewMenuOpened {
    width: 100%;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    margin-bottom: -1rem;
    z-index: 999;
}

.responsiveViewMenuClosed {
    display: none;
}

.responsive-navigation {
    cursor: pointer;
}

.close-button {
    position: absolute;
    top: 7px;
    right: 7px;
}

.close-button:hover {
    cursor: pointer;
}

.colors-button-group {
    display: flex;
}

.button-nazaj {
    margin-bottom: 1rem;
}

.stekla {
    display: flex;
    flex-wrap: wrap;
}

.steklo-panel {
    width: 8rem;
    text-align: center;
    border: 2px solid #343a40;
    border-radius: .25rem;
    font-weight: 600;
    background-color: #fdfdfd;
    margin: 1rem;
}

.steklo-panel-active {
    border: 2px solid #ff8800;
}

.steklo-panel:hover {
    cursor: pointer;
    border: 2px solid #ff8800;
    transition: 300ms;
}

.steklo-panel-img {
    border-bottom: 2px solid #bdbdbd;
}

.steklo-panel .steklo-panel-img img {
    height: 10rem;
    padding: 1rem;
}

.steklo-panel .steklo-panel-text {
    padding: 1rem;
}

.rocaj-panel {
    width: 15rem;
    text-align: center;
    border: 2px solid #343a40;
    border-radius: .25rem;
    font-weight: 600;
    background-color: #fdfdfd;
    margin-bottom: 2rem;
}
.rocaj-panel:hover {
    cursor: pointer;
    border: 2px solid #ff8800;
    transition: 300ms;
}

.rocaj-panel-img {
    border-bottom: 2px solid #bdbdbd;
}

.rocaj-panel .rocaj-panel-img img {
    width: 50%;
}

.rocaj-panel .rocaj-panel-text {
    padding: 1rem;
}

.zunanji-modeli h2 {
    text-align: center;
    margin-bottom: 1rem;
}

.notranji-modeli h2 {
    text-align: center;
    margin-bottom: 1rem;
}

.drzala-panel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    height: 10rem;
    padding: 1rem;
}

.drzala-panel h5 {
    font-weight: 600;
}

.kvadratna-drzala {
    background-image: url("./assets/images/bg-rocaj-kvadratni.jpg");
}

.okrogla-drzala {
    background-image: url("./assets/images/bg-rocaj-okrogli.jpg");
}

.utopni-rocaji {
    background-image: url("./assets/images/bg-rocaj-utopni.jpg");
}

.utopni-rocaji-led {
    /* background-image: url("./assets/images/bg-rocaj-okrogli.jpg"); */
}

.inox-vgradni-rocaji {
    /* background-image: url("./assets/images/bg-vgradni-rocaj.jpg"); */
}

.inox-vgradni-led-rocaji {
    /* background-image: url("./assets/images/bg-rocaj-okrogli.jpg"); */
}

.ukrivljeni-rocaji {
    /* background-image: url("./assets/images/bg-rocaj-okrogli.jpg"); */
}

.kljuke {
    background-image: url("./assets/images/bg-kljuke.jpg");
}



/* @media only screen and (max-height: 1200px) {
    .door-container svg {
        height: 500px;
    }
} */
/* 
@media only screen and (max-height: 777px) {
    .door-container svg {
        height: 400px;
    }
}

@media only screen and (max-height: 700px) {
    .door-container svg {
        height: 300px;
    }
}

@media only screen and (max-height: 570px) {
    .door-container svg {
        height: 200px;
    }
}

@media only screen and (max-height: 550px) {
    .door-container svg {
        height: 150px;
    }
} */

@media only screen and (min-width: 849px) {
    .hide-on-desktop {
        display: none;
    }
}

@media only screen and (max-width: 848px) {
    .hide-on-mobile {
        display: none;
    }

    .buttons-wall-bg {
        margin: .5rem 1rem;
    }
    
    .ceral-logo img {
        width: 15rem;
    }

    .navigation-top-tabs .rs-nav-item, .navigation-top-tabs .rs-nav-bar {
        display: none;
    }

    .configurator {
        width: 100%;
        height: 90vh;
    }

    .configuratorSidebar {
        height: 100%;
    }

    .button-povprasevanje {
        top: 80vh;
        bottom: unset;
    }
}

@media only screen and (max-width: 450px) {
    .buttons-ozadje-pogled {
        flex-direction: column;
    }

    .buttons-ozadje-pogled div {
        margin: .5rem;
    }

    .footer {
        flex-direction: column;
    }

    .button-povprasevanje {
        top: 79vh;
    }

    .button-povprasevanje button {
        font-size: 1rem;
    }
}

/* @media only screen and (min-width: 1190) {
    
} */